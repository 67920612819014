
































import { Vue, Component, Prop } from "vue-property-decorator";
import groupModule from "@/store/modules/groups";
import taskModule from "@/store/modules/tasks";
import { Group } from "@/types/group";
import moment from "moment";
import GroupStat from "@/types/GroupStat";

@Component
export default class GroupIndicator extends Vue {
  @Prop({ default: null }) width!: string | null;
  @Prop({ default: 5 }) showNext!: number;

  get group(): Group | undefined {
    return groupModule.group;
  }

  get currentValue(): number {
    return taskModule.currentWeekScore ?? 0;
  }

  get stats(): GroupStat[] {
    if (!taskModule.stats) return [];
    return taskModule.stats
      .filter((item) =>
        moment().startOf("isoWeek").isSameOrBefore(moment(item.create_at))
      )
      .slice(0, this.showNext);
  }

  get values(): number[] {
    return this.stats.map((item) => item.wes);
  }

  get labels(): string[] {
    return this.stats.map((item) => moment(item.create_at).format("D MMM"));
  }

  get hasValues(): boolean {
    return this.values.length > 0;
  }

  get avg(): number {
    return taskModule.medianWeekScore;
  }
}
