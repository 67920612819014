












































import { Group } from "@/types/group";
import { Component, Vue } from "vue-property-decorator";
import groupModule from "@/store/modules/groups";

@Component
export default class GroupSelector extends Vue {
  get group(): Group | undefined {
    return groupModule.group;
  }

  get groups(): Group[] {
    return groupModule.groups.filter((group) => group.id != this.group?.id);
  }

  get hasManyGroups(): boolean {
    return this.groups.length > 0;
  }

  async navTo(groupId: string): Promise<void> {
    try {
      await groupModule.selectGroup(groupId);
      this.$router.push({ name: "Group", params: { group_id: groupId } });
    } catch {
      //pass
    }
  }
}
