
































































import Vue from "vue";
import Component from "vue-class-component";
import GroupSelector from "@/components/group/GroupSelector.vue";
import groupModule from "@/store/modules/groups";
import taskModule from "@/store/modules/tasks";
import memberModule from "@/store/modules/members";
import { Group } from "@/types/group";
import { Dictionary } from "@/types/helpers";
import GroupIndicator from "@/components/group/GroupIndicator.vue";

@Component({
  components: {
    GroupSelector,
    GroupIndicator,
  },
})
export default class GroupContainer extends Vue {
  groupId = this.$route.params.group_id;
  activeTab = "tasks";

  get group(): Group | undefined {
    return groupModule.group;
  }

  get pendingCount(): number {
    return memberModule.pending.length ?? 0;
  }

  get tasksCount(): number {
    return taskModule.tasksFuture.length ?? 0;
  }

  get isGroupLoaded(): boolean {
    return groupModule.status == "loaded";
  }

  get isTasksLoaded(): boolean {
    return taskModule.status == "loaded";
  }

  get isLoaded(): boolean {
    return this.isGroupLoaded && this.isTasksLoaded;
  }

  // https://stackoverflow.com/questions/49721710/how-to-use-vuetify-tabs-with-vue-router
  get tabs(): Dictionary<Dictionary<string | number>> {
    return {
      tasks: {
        icon: this.$t("task.icon").toString(),
        count: this.tasksCount,
        color: "",
      },
      subjects: {
        icon: this.$t("subject.icon").toString(),
      },
      calendar: {
        icon: "mdi-calendar",
      },
      timeline: {
        icon: "mdi-chart-timeline",
      },
      stats: {
        icon: "mdi-chart-box-outline",
      },
      settings: {
        icon: "mdi-tune",
        count: this.pendingCount,
        color: "error",
      },
    };
  }

  async created(): Promise<void> {
    try {
      await groupModule.selectGroup(this.groupId);
    } catch (err) {
      if (err.response.status == 404) {
        this.$router.replace({ name: "NotFound" });
      } else {
        this.$toast.error(err.response.data.message);
      }
    }
  }
}
